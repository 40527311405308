// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #f5f8f2;
$border-radius: .4rem;
$success: #7cb932;

@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
 //@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group"; // Requires forms
@import "bootstrap/scss/custom-forms";
//@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

//
// Custom styles
//

@import "icon-list";

body {
  font-family: 'Inter', sans-serif;
}

// Style Bootstrap icons
.bi {
  fill: currentColor;
}



.logo {
  width: auto;
  height: 50px;
}


.carta-container {
  text-align: center;
  margin-top: 2rem;
  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
}

.carta {
  width: 90%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (min-width: 576px) {
    width: 70%;
  }
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 992px) {
    width: 90%;
  }
}

.blob-carta {
  width: 100%;
  height: auto;
  animation: float-15 5s .6s ease-in-out infinite;
  @media screen and (min-width: 576px) {
    width: 80%;
  }
  @media screen and (min-width: 768px) {
    width: 55%;
  }
  @media screen and (min-width: 992px) {
    width: 100%;
  }
}


.titolone {
  font-size: 2rem;
  @media screen and (min-width: 768px) {
    font-size: 2.8rem;
    padding-right: 8rem;
  }
  @media screen and (min-width: 992px) {
    padding-right: 6rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }
}

.main-features {
  font-size: 1.15rem;
  padding-left: 2.5rem;
  text-indent: -2.3rem;
}

.main-features li {
  padding-bottom: 0.5rem;
}

.secondary-link {
  font-size: 1rem;
}


.btn {
  font-size: 1rem;
  padding: 0.75rem 1rem;
}

.btn-outline-success:hover,
.btn-outline-success:active {
  color: #fff;
}


.text {
  font-size: 1.15rem;;
}


.blob {
  width: 60%;
  height: auto;
  display: block;
  margin: 0 auto;
  animation: float-5 5s .6s ease-in-out infinite;
}


.maialino {
  width: 35%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bandiera {
  width: 35%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mano-usd {
  width: 35%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fa-primary {
  fill: $success;
}

.fa-secondary {
  fill: red;
}

@keyframes float-15 {
  0%, to {
    transform:translateY(0)
  }
  50% {
    transform:translateY(-15px)
  }
}

@keyframes float-5 {
  0%, to {
    transform:translateY(0)
  }
  50% {
    transform:translateY(-5px)
  }
}



.blockquote-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.quote-icon {
  width: 8rem;
  height: auto;
  left: -0.5rem;
  bottom: 2rem;
  transform: scaleX(-1);
  opacity: 0.3;
  @media screen and (min-width: 576px) {
    left: 0.5rem;
  }
  @media screen and (min-width: 1200px) {
    width: 10rem;
    left: 0;
  }
}

.blockquote {
  margin-bottom: 0;
}

.blockquote__text {
  font-size: 1.5rem;
  z-index: 1;
}


.blockquote-image-container {
  padding: 0;
  @media screen and (min-width: 768px) {
    padding: 0;
  }
}

.blockquote-image {
  object-fit: cover;
  object-position: center;
  height: 100%;
}









.form-container {
  margin-top: 3rem;
  margin-bottom: 6rem;
}

.form-richiedi-info {
  scroll-margin-top: 92px; //fix scroll-to position
}

.arrow {
  opacity: 0.75;
  width: 3rem;
  height: auto;
  @media screen and (min-width: 768px) {
    transform: rotate(-90deg);
  }
}

.form-control {
  border: 1px solid #ced4da;
  box-shadow: inset 0 2px 4px hsla(0, 0%, 0%, 0.08);
}

.submit-icon {
  display: none;
  position: absolute;
  top: 1rem;
}

.grazie {
  font-size: 1.25rem;
  font-weight: 500;
}








.footer-title {
  font-size: 1.15rem;
  opacity: 0.75;
}

.footer-list a {
  color: #fff;
  opacity: 0.75;
}


.logo-footer {
  width: 100%;
  height: auto;
  @media screen and (min-width: 576px) {
    width: auto;
    height: 150px;
  }
}


.partner {
  opacity: 0.75;
}


.change-capital {
  width: 200px;
  height: auto;
}



.credits {
  height: 20px;
  width: auto;
}